<template>
  <div>
    <w-top-bar>Productos</w-top-bar>
    <div class="h-full p-5 bg-gray-200">
      <div class="mb-2">
        <label
          for="input"
          class="block text-sm font-medium leading-5 text-gray-700"
          >Busqueda</label
        >
        <input
          v-model="filtroTexto"
          id="searchInput"
          :class="[
            'block w-full px-2 py-2 mt-1 border rounded ring-1 ring-black ring-opacity-5 boder-gray-400 form-input sm:text-sm sm:leading-5',
          ]"
          placeholder="Buscar producto por nombre o SKU"
        />
      </div>

      <div v-if="products.length > 0" class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <div
              class="overflow-x-scroll border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                    >
                      Producto
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                    >
                      Stock
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 text-center tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                    >
                      Publico
                    </th>
                  </tr>
                </thead>
                <tr
                  class="bg-white"
                  :class="{ 'opacity-50': !product.isPublic }"
                  v-for="product in products"
                  :key="product._id"
                >
                  <td class="px-6 whitespace-nowrap">
                    <div
                      class="flex items-center text-sm font-medium leading-5 text-gray-900"
                    >
                      <img
                        class="w-10 h-10 pr-2 rounded"
                        :src="product.image"
                      />
                      <div class="">
                        <div>{{ product.name }}</div>
                        <div class="text-xs font-normal">{{ product.sku }}</div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span>
                      {{ product.stock }}
                    </span>
                    <!--<div class="flex items-center">
                       <w-input
                        v-model="product.stock"
                        @blur="updateStock(product)"
                        class="w-32"
                      />
                      <div class="w-12">
                        <svg
                          v-show="isUpdatingStock === product._id"
                          class="w-4 h-4 text-blue-500 animate-spin ml-1"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            class="opacity-50"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                          ></circle>
                          <path
                            class="opacity-100"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </div> 
                    </div>-->
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex justify-center items-center">
                      <w-switch
                        :value="product.isPublic"
                        @input="togglePublic(product, $event)"
                      ></w-switch>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <w-loading v-else />
    </div>
  </div>
</template>

<script>
import { makeFindMixin } from "feathers-vuex"; // Step 1
import { mapActions } from "vuex";
import WLoading from "../../components/WLoading.vue";
// import _ from "lodash";

export default {
  components: { WLoading },
  name: "productsList",
  data() {
    return {
      localProducts: false,
      productType: null,
      isUpdatingStock: null,
      filtroTexto: "",
    };
  },
  mixins: [makeFindMixin({ service: "products" })], // Step 2
  computed: {
    productsParams() {
      let query = {};
      if (this.filtroTexto !== "") {
        query.$or = [
          { name: { $regex: this.filtroTexto } },
          { sku: { $regex: this.filtroTexto } },
        ];
      }
      return {
        query: {
          ...query,
          $sort: {
            isPublic: -1,
            sku: 1,
          },
          $limit: 1000,
        },
      };
    },
  },
  // watch: {
  //   // debounce the watcher to avoid making the request too often
  //   filtroTexto: _.debounce(function () {
  //     this.findProducts();
  //   }, 500),
  // },
  methods: {
    ...mapActions("products", {
      patchProduct: "patch",
    }),
    async updateStock(product) {
      this.isUpdatingStock = product._id;
      await this.patchProduct([product._id, { stock: product.stock }]);
      setTimeout(() => {
        this.isUpdatingStock = null;
      }, 200);
    },
    togglePublic(product, status) {
      product.isPublic = status;
      this.patchProduct([product._id, { isPublic: status }]);
    },
    update() {
      this.findProducts();
    },
    ...mapActions("products", { create: "create" }),
    fake() {
      console.log("fake");
      this.create({
        name: "Libro Prueba",
        description: "Libro Prueba",
        image:
          "https://www.hernancasciari.com/img/secciones/libros/articulos/id006232/renesp-960.png",
        price: {
          ars: 1000,
          usd: 2000,
        },
        type: "book",
        delivery: "physic",
        public: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
