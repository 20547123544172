import { render, staticRenderFns } from "./general.vue?vue&type=template&id=4b9a0ab9&scoped=true&"
import script from "./general.vue?vue&type=script&lang=js&"
export * from "./general.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b9a0ab9",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WLoading from '@/components/WLoading.vue'
import WSwitch from '@/components/WSwitch.vue'
import WTopBar from '@/components/WTopBar.vue'
autoInstallComponents(component, {WLoading,WSwitch,WTopBar})
